:root {
  --background-color: #132257;
  --wild-color: #76C5D3;
  --navbar-color: rgba(19,34,87, 1);
  --text-color: #000;
  --main-color: #132257;
  --nav-link-color: #ffffff;
  --nav-link-brand-color: #87c7cd;
  --highlight-color: #756A61;
  --footer-color: #fff;
  --cololkfjdlfkdjsjklfd: #95dd83;
}

@font-face {
  font-family: Hiatus;
  src: url("./fonts/Hiatus-MVJnw.otf") format("opentype");
}

@font-face {
  font-family: PatrickHand;
  src: url("./fonts/FTP\ -\ Monreta.otf") format("opentype");
}

@font-face {
  font-family: totReg;
  src: url("./fonts/OptimaNovaLTProRegular.otf") format("opentype");
}

@font-face {
  font-family: totBold;
  src: url("./fonts/OptimaNovaLTProBold.otf") format("opentype");
}



/*--navbar-color: #2d4221;*/

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

html,
body {
  font-family: "PatrickHand","Poppins", Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

h1,
h2 {
  font-weight: 700;
  font-size: 36px;
}

h3 {
  font-weight: 700;
  font-size: 24px;
}

h4 {
  font-weight: 600;
  font-size: 18px;
}

p {
  font-size: 18px;
}

a {
  color: var(--main-color);
}


.homeContainer{
  justify-content: center;
  align-items: center;
  vertical-align:middle;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height:95%;
  overflow:hidden;
}f

.PortfolioContainer{
  justify-content: center;
  align-items: center;
  vertical-align:middle;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.PortfolioContainerMobile{
  padding-top:30%;
  justify-content: center;
  align-items: center;
  vertical-align:middle;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobileDiv{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mobilePortfolioText{
  color: #FFF;
  text-decoration:underline
}

.expCol{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.expRow{
  padding-top: 4%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.contactRow{
  width:70%;
  justify-content: center;
  padding-bottom: 6%;
}

.firstContactRow{
  padding-top:10%;
}

.contactCol{
  align-items: center;
  justify-content: center;
  display: flex;
}

.test{
  justify-content: center;
  text-align: center;
  padding-top: 20%;
}

.btn-huge {
  padding: 5rem 10rem;
  font-size: 1.5rem;
}

.buttonTitleBold{
  font-size: 30;
  font-family: "PatrickHand";
}

.patientProgress{
  padding-top: 7%;
}

.calendar-container{
  padding-top: 20%;
}

.schText{
  padding-top: 45%;
}

.aboutTitle{
  padding-top: 1.5%;
}

.HDTW{
  padding-bottom: 2%;
}

.HDTWrow{
  padding-top: 4%;
}

.HTDWtitle{
  padding-top: 75%;
}

.selector{
  padding-top:2%;
}

.about{
  background-color: var(--wild-color);
}

.scheduler{
  justify-content: center;
  text-align: center;
  padding-top: 15%;
}

.col-4{
  justify-content: center;
  text-align: center;
}

.frontGif{
  width: '20%';
}

.navbar {
  background-color: var(--navbar-color);
  --bs-navbar-padding-y: 0;
  font-family: totReg;
}

.navbar.primary {
  background-color: var(--navbar-color);
}

.navbar .navbar-brand {
  font-weight: 500;
  color: '#ffffff';
  --bs-navbar-padding-y: 0;
  --bs-navbar-brand-padding-y: 0;
}

.navbar .navbar-nav .nav-link {
  color: var(--nav-link-color);
  margin-left: 15px;
  margin-right: 15px;
  transition: 0.25s all;
}

.navBarResume{
  color: var(--nav-link-color);
  margin-right: 30px;
  transition: 0.25s all;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus,
.navbar .navbar-nav .nav-link:focus-visible {
  transition: 0.25s all;
  color: var(--highlight-color);
}

.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus,
.navbar .navbar-brand:focus-visible {
  transition: 0.25s all;
  color: #6aa8ad;
}

.primary {
  background-color: var(--main-color);
}

.primaryHome{
  position: absolute;
  width:100%;
}

.box {
  border: 1px solid black;
  min-height: 50px;
  padding: 10px;
  border-radius: 10%;
  margin: 1%;
  transition: box-shadow 0.3s;
  background-color: #f0ffec;
}

.square:before {
  content: "";
  display: block;
  padding-top: 50%; /* initial ratio of 1:1*/
  padding-bottom: 50%;
}

.float-left {
  float: left;
}

.background-image {
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  cursor: pointer;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.header-div {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  margin-top: 10%;
  margin-bottom: 3%;
  background-color: #FAAB5B;
  border-radius: 2px;
}

.auth input {
  margin-bottom: 10px;
}

.auth button {
  margin-bottom: 10px;
}

.auth button {
  background-color: var(--main-color);
}


.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }
 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
 }
 .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
 }

 .homeCol{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
 }

 
.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  }

.mapboxgl-marker{
  background-image: url('./photos/spursLogo.png');
  background-size: cover;
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}

.markerText{
  margin-bottom:0;
}

.mapboxgl-popup-content {
  text-align: center;
  justify-content: center;
  display: flex;
  font-family: totReg !important;
  }

.homeText{
  color: white;
  font-family: totReg;
  font-size:25px;
}

.whitsText{
  color:white;
  font-family:totReg;
  font-size: 35px;
  text-decoration: none;
}

.welcomeTo{
  color:white;
  font-family:totReg;
  width:100%;
}
.homeName{
  color:white;
  font-family:totReg;
  width:100%;
}

.matchesMonthText{
  color:white;
  font-family:totReg;
}

.homeColRight{
  text-align: left;
  padding-bottom:8%;
}

.arrowHover:hover {
  color: #756A61;
}

.fixtureImage{
  padding-left: 0% !important;
  padding-right: 0% !important;
  object-fit: contain;
} 

.nextMatch {
  box-shadow:
  0 0 0 8px #132257,
  0 0 0 16px #ffffff;
  z-index: 3;
}

.smallNextMatch {
  box-shadow:
  0 0 0 3px #132257,
  0 0 0 6px #ffffff;
  z-index: 3;
}

.matchShowingsText{
  color:white;
  font-family:totReg;
}

.Mui-selected{
  background-color: #756A61 !important;
  color: #ffffff !important;
}

.image-gallery-image{
  max-height:60vh !important;
}

.image-gallery{
  max-width: 80vw !important;
}

@media only screen and (max-width: 600px) {
  h1,
  h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 360px) {
}
